<template>
<div>
  
                        

      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    


            <h4 class="col ml-3 font-weight-lighter var_color"> <span class="fa fa-cart-arrow-down"></span> Bons De Livraison ( Validés )    </h4> 

                    <div class="col">

                                    <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>

                                    <b-button pill size="sm"  class="m-2 float-right" variant="info" @click="fetchList">
                                        <i class="fa fa-sync-alt"></i>
                                        <span> Actualiser</span> 
                                    </b-button>
                    </div>
            </div>
       



        <b-table 
        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"
        responsive 
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">
                    <b-button v-if="d_gst_livrais_val==true" pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-warning" @click="devald_click(row.item)">
                                <i class="fa fa-check"></i>
                        
                    </b-button>


                    <b-button v-if="d_gst_livrais_val==true" pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-primary" @click="edit_click(row.item)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>

                    
                    <b-button v-if="d_gst_livrais_val==true" pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-secondary" @click="Ckick_exportDox(row.item)">
                            <i class="fa fa-print"></i>
                    
                    </b-button>

                    <b-button pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-info" @click="fetchListArts(row.item)">
                            <i class="fa fa-bars"></i>
                    
                    </b-button>


                    <b-button  v-if="row.item.is_img==1" pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="clic_uplode_image(row.item)">
                            <i class="fa fa-image"></i>
                    </b-button>

                </template>
        
        </b-table>
                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>


    <!-- le modal -->
    <b-modal id="detailModal" :title=curentBN size="lg" hide-footer>


       <b-table 
        small 
        borderless
        striped 
        sticky-header
        :fields="fieldsArticle" 
        :items="ArticlesList"
        >

        
        </b-table>
              <div class="card border-info">
                        <div class=" d-flex justify-content-center pt-1 text-info">
                                <h5 class="font-weight-light ">Total Prix : {{total_price}} </h5>
                        </div>
              </div>
    </b-modal>
    <!-- les trucs public -->

<loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

        
        <uploadBonImg v-bind:id_bon="itemFormImage.id_bon"
        :rep_url="itemFormImage.rep_url"
        :url="itemFormImage.url"
        :img_name="itemFormImage.img_name"
        :typ="itemFormImage.typ"
        ></uploadBonImg>

</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
 import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
 import { saveAs } from 'file-saver';
import uploadBonImg from '@/components/UploadImg/UploadBonImg.vue';

export default {
    computed: {



        d_logo_w() {
      return this.$store.getters.get_logo_w;
    },
            d_logo_h() {
      return this.$store.getters.get_logo_h;
    },
            d_logo_img() {
      return this.$store.getters.get_logo_img;
    },
                entet_pag() {

      if(this.$store.getters.get_entete)
      {
            return this.$store.getters.get_entete;
      }
      else
      {
            return '';
      }
    },
    sousentet_pag() {

      if(this.$store.getters.get_sousentete)
      {
            return this.$store.getters.get_sousentete;
      }
      else
      {
            return '';
      }
    },
    pied_pag() {

      if(this.$store.getters.get_pied)
      {
            return this.$store.getters.get_pied;
      }
      else
      {
            return '';
      }
    },
              rows_pagin() {
        return this.itemslist.length
      },
            user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },
        d_gst_livrais_val() {
      return this.$store.getters.get_gst_livrais_val;
    },
        total_price: function() {
      return this.ArticlesList.reduce((a, b) => +a + +(b.qte * b.prix), 0).toFixed(2);
    }
    },
    mounted(){
        this.fetchList()
    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,
            fieldsArticle: [
                {key: 'ref', label: 'Réf'},
                {key: 'qte', label: 'Quantité'},
                {key: 'unite', label: 'Unité'},
                {key: 'prix', label: 'Prix'},
                {key: 'nom_lot', label: 'Lot'},
                {key: 'description', label: 'Description'}
            ],
            ArticlesList:[],

            curentBN:'',

            itemslist: [],

            fields: [
                {key: 'nom', label: 'N°', sortable: true},
                {key: 'fournisseur', label: 'Fournisseur', sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'depot', label: 'Dépôt', sortable: true},
                {key: 'total_ttc', label: 'Total TTC', sortable: true},
                {key: 'valider', label: 'Valider', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                {key: 'regler', label: 'Reglé', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                {key: 'description', label: 'Description', sortable: true},
                 {key: 'user', label: 'Utilisateur', sortable: true},
                { key: 'actions', label: '' }
            ],

             itemFormImage: {
                    id_bon:'',
                    rep_url:'',
                    url:'',
                    img_name:'',
                    typ:'BLs'
                    },

            //public trucs

            isLoading: false
		}
	},
    validations: {
            itemForm: {
                nom: {
                    required
                }
            }
    },
    methods :{
        
    clic_uplode_image(bon)
    {

                    this.isLoading=true;
                    this.$http.get('/getImageUrl/BLs/'+bon.img_name)
                    .then((result) => {


                            if(result.data.data!=null)
                            {
                                    this.itemFormImage.rep_url=result.data.data
                                    this.itemFormImage.url=null
                                    this.itemFormImage.id_bon=null
                                    this.itemFormImage.img_name=bon.img_name
                                    this.itemFormImage.typ='BLs'
                                    this.$root.$emit('bv::show::modal', 'uploadimgmodal')
                            }
                            else
                            {
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                            }


                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });





        },



Ckick_exportDox(rowitem)
{
                    this.isLoading=true;
                    this.$http.get('/detailbonLivraison/'+rowitem.id)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;


                                //telecharger rapp
                                this.exportDox(rowitem)

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
},
exportDox(rowitem)
{


let datrw =[new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 4000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                    children: [
                                            new TextRun({
                                            text: "Référence",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        spacing: {
                                        before: 100,
                                        after: 100
                                    },
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),


                new TableCell({
                    width: {
                        size: 6000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                                      children: [
                                            new TextRun({
                                            text: "Description",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                                        children: [
                                            new TextRun({
                                            text: "Quantité",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                                          children: [
                                            new TextRun({
                                            text: "Prix",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                                new TableCell({
                    width: {
                        size: 3000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                     children: [
                                            new TextRun({
                                            text: "Lot",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),



            ],
        })]

this.ArticlesList.forEach(function(item){

let r = new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 4000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                    children: [
                                        new TextRun({
                                            text: item.ref,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                  spacing: {
                                            before: 100,
                                            after: 100,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),


                new TableCell({
                    width: {
                        size: 6000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                            children: [
                                        new TextRun({
                                            text: item.description,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        spacing: {
                                            before: 10,
                                            after: 10,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                                         children: [
                                        new TextRun({
                                            text: item.qte.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],

                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                                                      
                                  children: [
                                        new TextRun({
                                            text: item.prix.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],

                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                new TableCell({
                    width: {
                        size: 3000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                                           
                                  children: [
                                        new TextRun({
                                            text: item.nom_lot,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ], 
                                                                          spacing: {
                                            before: 10,
                                            after: 10,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),


            ],
        })

  datrw.push(
 r
)
});

  

const table = new Table({

    rows:  datrw
})    
      

        //pied

                let datrw_salare_net =[new TableRow({
                    children: [
                            
                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                    
                                        children: [
                                            new TextRun({
                                            text: "Total TTC",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                    spacing: {
                                                    before: 80,
                                                    after: 80
                                                },
                                                heading: HeadingLevel.HEADING_1,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                                new TableCell({
                                    width: {
                                        size: 6250,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                               
                                        children: [
                                            new TextRun({
                                            text:  this.total_price.toString() + " DH",
                                            color: '000000',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                heading: HeadingLevel.HEADING_1,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                            ],
                        })]

                const tablenet = new Table({

                    rows:  datrw_salare_net
                })

        //fin pie

      const doc = new Document({
    sections: [
        {
            properties: {
                page: {
                    pageNumbers: {
                        start: 1,
                        formatType: PageNumberFormat.DECIMAL,
                    },
                    margin:{
                                left:500,
                                right:500
                            }

                },

            },
                    headers: {

                        default: new Header({
                            children: [




                  /*  new Paragraph({
                    children: [


                        new ImageRun({
                            
                        data: Uint8Array.from(atob(this.d_logo_img), c =>
                            c.charCodeAt(0)
                        ),
                        transformation: {
                            width: this.d_logo_w,
                            height: this.d_logo_h
                        },



                        })
                    ]
                    }),*/
                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.entet_pag,
                                color: '000000',
                                bold: true,

                                }),
                                    ],
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),
                             new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.sousentet_pag,
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                                })

                                    ],
                                border: {
                                bottom: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_1,
                                    alignment: AlignmentType.CENTER,
                                }),




                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.pied_pag,
                                color: '000000',
                                bold: false,
                                })

                                    ],
                                border: {
                                top: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_3,
                                    alignment: AlignmentType.CENTER,
                                }),

                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                            color: '000000'
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                            color: '000000'
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },



            children: [




                new Paragraph({



                    children: [

                        new TextRun({
                        text: "Bon De livraison",
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    }),
                    ],

                    heading: HeadingLevel.HEADING_1,
                                        alignment: AlignmentType.CENTER,

                        spacing: {
                            after: 200,
                            before: 200
                        },
                }),




                new Paragraph({



                    children: [

                        new TextRun({
                        text: "N° : "+rowitem.nom+"          Fournisseur : "+rowitem.fournisseur,
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    }),

                        new TextRun({
                        text: "          Dépôt : "+rowitem.depot,
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    })
                    ],

                    heading: HeadingLevel.HEADING_3,
                    alignment: AlignmentType.CENTER,

                        spacing: {
                            after: 100,
                            before: 100
                        },
                }),
                
                

                new Paragraph({



                    children: [
                        new TextRun({
                        text: "De la part de : ",
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    }),
                        new TextRun({
                        text: rowitem.user,
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    }),
                    new TextRun({
                        text: "          Date : ",
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    }),

                        new TextRun({
                        text: rowitem.date,
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    })
                    ],

                    heading: HeadingLevel.HEADING_3,
                    alignment: AlignmentType.CENTER,

                        spacing: {
                            after: 300,
                            before: 100
                        },
                }),

                table,
                new Paragraph({
                                    children: [

                                        new TextRun({
                                        text: "",
                                        color: '000000',
                                        bold: false,
                                        font: "Segoe UI"
                                    }),

                                    ],

                                    heading: HeadingLevel.HEADING_3,
                                                        alignment: AlignmentType.CENTER,

                                        spacing: {
                                            after: 50,
                                            before: 50
                                        },
                }),

                tablenet
                
                ]
        },
    ],
});
      
      
      











    Packer.toBlob(doc).then(blob => {

  saveAs(blob, rowitem.nom+".docx");

    });

},
        edit_click(item) {

                this.$confirm("Voulez-vous vraiment Devalider et modifier ce Bon ??")
                 .then(() => {

                    this.BnDevalider(item.id,true)

                })
                .catch(error=> {
                console.log(error)

                });


        },
            devald_click(item) {

                                    this.$confirm("Voulez-vous vraiment Devalider ce Bon??")
                                    .then(() => {

                                        this.BnDevalider(item.id,false)
                                    })
                                    .catch(error=> {
                                    console.log(error)

                                        });




        },
        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/bnLivraisonType/1/'+this.user_dep)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },



        BnDevalider(id_bn,edit){

            this.isLoading=true;

            this.$http.get('/validBonLivraison/'+id_bn+'/0')
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Bon Devalidé',
                message: 'Les données sont devalidées correctement'
                })

                    if(edit)
                    {
                                            this.$router.push({ 
                                            name: 'bnLivraisonFour', 
                                            params: { 
                                            ResivedId: id_bn
                                            } 
                                            });
                    }
                    else
                    {
                                    let index = this.itemslist.findIndex(item => item.id === id_bn)
                                    this.itemslist.splice(index, 1)

                    }




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de devalider les données .. Réessayez !!'
                })
                });

        },
        fetchListArts(row_bn)
        {
                    this.isLoading=true;
                    this.$http.get('/detailbonLivraison/'+row_bn.id)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;


                                this.curentBN=row_bn.nom + " ( "+row_bn.date+" )"
                                this.$root.$emit('bv::show::modal', 'detailModal')

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
        NewBon()
        {
                    this.$router.push({ 
                        name: 'menuLivraison'
                    });
        },
    },
    components: {
              Loading,
              uploadBonImg      
    }
}
</script>

<style>
.var_color{
  color: #c98a8c;
}
</style>